/* mainLoading.module.scss */

.container {
    @apply flex items-center justify-center h-screen bg-default-50 fixed inset-0 z-50 duration-400 transition-all;
}

.hidden {
    @apply opacity-0 pointer-events-none;
}

.visible {
    @apply opacity-100 pointer-events-auto;
}