.uiProviders {
    @apply h-dvh flex flex-col overflow-hidden max-h-dvh;
}

.content {
    @apply grow flex overflow-hidden;
}

.scrollShadow {
    @apply grow flex flex-col;
}

.main {
    @apply flex-1 flex flex-col;
}

.paddedMain {
    @apply p-3;
}